import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ArrowPathIcon as RefreshIcon, ChevronDownIcon, CheckCircleIcon } from '@heroicons/react/20/solid'
import classNames from 'lib/classnames'
import { useLocalStorage } from 'lib/data/use-local-storage'

interface Props {
  onClick: () => void
}

const items = [
  {
    name: 'Stop auto refresh',
    value: 0
  },
  {
    name: 'Refresh every 10s',
    value: 10000
  },
  {
    name: 'Refresh every minute',
    value: 60000
  }
]

export function AutoRefreshButton({ onClick }: Props) {
  const [autoRefresh, setAutoRefresh] = useLocalStorage('autoRefresh', 0)
  const [timeLeft, setTimeLeft] = useState(0)
  const textRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    if (autoRefresh > 0) {
      const interval = setInterval(() => {
        const left = timeLeft - 1
        if (left <= 0) {
          setTimeLeft(autoRefresh / 1000)
          onClick()
        } else {
          setTimeLeft(left)
        }
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [autoRefresh, timeLeft])

  const setRefresh = (value: number) => {
    setAutoRefresh(value)
    setTimeLeft(value / 1000)
  }

  useEffect(() => {
    if (autoRefresh && textRef.current) {
      textRef.current.innerText = `Refresh in ${timeLeft}s`
    }
  }, [autoRefresh, timeLeft])

  const foreceRefresh = useCallback(() => {
    onClick()
    setTimeLeft(autoRefresh / 1000)
  }, [onClick, autoRefresh])

  return (
    <div className="inline-flex rounded-md shadow-sm">
      <button
        onClick={foreceRefresh}
        className={classNames(
          'group h-[30px] bg-gray-100',
          'hover:border-primary-600 active:bg-primary-700 hover:bg-primary-600 inline-flex items-center border border-gray-300 hover:text-white',
          'px-2.5',
          'text-ilabel',
          'font-ilabel',
          'gap-2',
          'py-1'
        )}
      >
        <RefreshIcon className="h-4.5 w-4.5" aria-hidden="true" />
        <span ref={textRef}>Refresh</span>
      </button>
      <Menu as="div" className="relative -ml-px block h-[30px]">
        {({ open }) => (
          <>
            <Menu.Button
              className={classNames(
                'relative inline-flex h-[30px] items-center rounded-r-md',
                'px-2 py-2 focus:z-10',
                'ring-1 ring-inset',
                open
                  ? 'bg-primary-600 text-white'
                  : 'hover:bg-primary-600 bg-gray-100 text-gray-400 ring-gray-300 hover:text-white'
              )}
            >
              <span className="sr-only">Open options</span>
              <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="dark:bg-sentio-gray-100 absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:ring-gray-100">
                <div className="py-1">
                  {items.map((item) => (
                    <Menu.Item key={'refresh'}>
                      {({ active }) => (
                        <button
                          onClick={() => setRefresh(item.value)}
                          className={classNames(
                            active ? 'text-text-foreground bg-gray-100' : 'text-gray-700',
                            'flex w-full justify-between px-4 py-2 text-sm'
                          )}
                        >
                          {item.name}
                          {autoRefresh === item.value ? (
                            <CheckCircleIcon className="inline-block h-5 w-5 text-[#0557d4]" />
                          ) : null}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  )
}
