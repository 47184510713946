import classNames from 'lib/classnames'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import { useEffect, useMemo, useState } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { Select } from '../common/select/NewSelect'
import { range } from 'lib/range'

dayjs.extend(weekday)
dayjs.extend(localeData)

interface Props {
  value: dayjs.Dayjs
  start?: dayjs.Dayjs
  end?: dayjs.Dayjs
  onSelect: (value: dayjs.Dayjs) => void
  className?: string
}

function SelectYear({ currentYear, onChange }: { currentYear: number; onChange: (year: number) => void }) {
  const years = useMemo(
    () =>
      range(currentYear - 20, currentYear + 20).map((y) => ({
        value: y,
        label: y + ''
      })),
    [currentYear]
  )
  return <Select options={years} value={currentYear} onChange={(v) => onChange(v)} />
}

function SelectMonth({ currentMonth, onChange }: { currentMonth: number; onChange: (month: number) => void }) {
  const months = useMemo(
    () =>
      dayjs.monthsShort().map((m, idx) => ({
        value: idx,
        label: m
      })),
    []
  )
  return <Select options={months} value={currentMonth} onChange={(v) => onChange(v)} />
}

export default function Calendar({ value, onSelect, className, start, end }: Props) {
  const [firstDay, setFirstDay] = useState(value.date(1))

  useEffect(() => {
    setFirstDay(value.date(1))
  }, [value])

  const days = useMemo(() => {
    const days: any[] = []
    let curr = firstDay.weekday(0).hour(0).minute(0).second(0)
    const lastDay = firstDay.endOf('month').weekday(6)
    while (curr.isBefore(lastDay)) {
      const isStart = curr.isSame(start, 'day')
      const isEnd = curr.isSame(end, 'day')
      days.push({
        date: curr,
        isCurrentMonth: curr.month() == firstDay.month(),
        isToday: dayjs().isSame(curr, 'day'),
        isStart: start && curr.isSame(start, 'day'),
        isEnd: end && curr.isSame(end, 'day'),
        isSelected: start && end && curr.isAfter(start) && curr.isBefore(end)
      })
      curr = curr.add(1, 'day')
    }
    return days
  }, [firstDay, start, end])

  return (
    <div className={classNames(className, 'hidden max-w-md pt-1 md:block')}>
      <div className="text-text-foreground mb-3 flex items-center text-center">
        <button
          onClick={() => setFirstDay(firstDay.subtract(1, 'month'))}
          type="button"
          className="mr-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <div className="basis-1/2">
          <SelectMonth currentMonth={firstDay.month()} onChange={(month) => setFirstDay(firstDay.month(month))} />
        </div>
        <div className="ml-1 basis-1/2">
          <SelectYear currentYear={firstDay.year()} onChange={(year) => setFirstDay(firstDay.year(year))} />
        </div>
        <button
          onClick={() => setFirstDay(firstDay.add(1, 'month'))}
          type="button"
          className="ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="grid grid-cols-7 text-center text-xs leading-6 text-gray-600">
        {dayjs.weekdaysShort().map((day, index) => (
          <div key={index}>{day}</div>
        ))}
      </div>
      <div className="isolate mt-2 grid gap-y-1 text-xs" style={{ gridTemplateColumns: 'repeat(6, 1fr) auto' }}>
        {days.map(({ date, isCurrentMonth, isToday, isSelected, isStart, isEnd }, dayIdx) => (
          <div
            className={classNames(
              !isCurrentMonth && 'invisible',
              isStart && end && 'bg-primary-100 rounded-l-full',
              isSelected && !isEnd && 'bg-primary-100'
            )}
            key={date}
          >
            <button
              onClick={() => onSelect(date)}
              type="button"
              className={classNames(
                'dark:hover:bg-primary-400 relative rounded-full hover:bg-gray-100 focus:z-10',
                date.weekday() === 6 ? 'mr-1.5' : 'mr-2.5',
                isEnd && start && 'bg-primary-100 rounded-r-full'
              )}
            >
              <time
                dateTime={date.format('YYYY-MM-DD')}
                className={classNames(
                  'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                  isStart && 'bg-primary-600 rounded-full text-white',
                  isEnd && 'bg-primary-600 rounded-full text-white'
                )}
                aria-selected={isSelected}
              >
                {date.date()}
              </time>
              {isToday && <div className="absolute bottom-0.5 left-3 h-1 w-1 rounded-full bg-[#3BB083]" />}
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}
