import { AnalyticService, SegmentationRequest } from 'gen/service/analytic'
import useApi from './use-api'
import { useProjectVersions } from './use-project-versions'
import * as CommonCommon from 'gen/service/common/protos/common.pb'
import { dateTimeToString, DateTimeValue, durationToSeconds } from '../time'
import { Duration, SegmentationQuery, SegmentationQuerySelectorExpr } from 'gen/service/common'
import { produce } from 'immer'
import { useProject } from './use-project'
import { JoinOperator, Permission, Project } from '../../gen/service/common'
import isEmpty from 'lodash/isEmpty'
import { calculateInterval } from '../interval'

export function makeSegmentPayload(
  projectOwner: string,
  projectSlug: string,
  chart: { segmentationQueries?: SegmentationQuery[]; formulas?: CommonCommon.Formula[] },
  startTime: DateTimeValue,
  endTime: DateTimeValue,
  step: Duration | undefined,
  tz?: string,
  limit?: number,
  offset?: number,
  version?: number,
  variablesValues: { [p: string]: string } = {}
) {
  const req: SegmentationRequest = {
    projectOwner,
    projectSlug,
    version: version,
    timeRange: {
      start: dateTimeToString(startTime),
      end: dateTimeToString(endTime),
      step: step ? durationToSeconds(step) : calculateInterval(startTime, endTime),
      timezone: tz
    },
    queries: resolveVariables(chart.segmentationQueries || [], variablesValues),
    formulas: chart.formulas,
    limit,
    offset
  }
  return req
}

export function useQuerySegmentation(
  projectId: string | undefined,
  startTime: DateTimeValue,
  endTime: DateTimeValue,
  tz: string,
  interval: Duration | undefined,
  queries: CommonCommon.SegmentationQuery[],
  formulas: CommonCommon.Formula[],
  variablesValues: { [p: string]: string } = {},
  limit = 20,
  fromShare?: { shareId?: string; panelId?: string; project?: Project }
) {
  const { currentVersion } = useProjectVersions()
  const { owner, slug } = useProject([Permission.READ])

  const req = makeSegmentPayload(
    fromShare?.project?.ownerName ?? (owner as string),
    fromShare?.project?.slug ?? (slug as string),
    { segmentationQueries: expandArraySelectors(queries), formulas },
    startTime,
    endTime,
    interval,
    tz,
    limit,
    0,
    currentVersion,
    variablesValues
  )
  const isReady =
    projectId &&
    queries.filter(
      (q) => q.resource?.name != null || q.resource?.cohortsId != null || isEmpty(q.resource?.cohortsQuery) === false
    ).length > 0
  const { data, loading, ready, error, mutate } = useApi(
    AnalyticService.QuerySegmentation,
    isReady ? req : null,
    false,
    {
      refreshInterval: 10000,
      fromShare
    }
  )

  return { data, error, loading, ready, mutate, payload: req }
}

export function useAnalyticsEvents(
  projectId?: string,
  fromShare?: { shareId?: string; project?: Project },
  fromExternalProject?: { projectId?: string; externalProjectId?: string }
) {
  const { currentVersion, project } = useProjectVersions()
  const { data, error, loading } = useApi(
    AnalyticService.GetEvents,
    projectId
      ? {
          projectId: fromExternalProject?.externalProjectId ?? projectId,
          // when request project id is not equal to current project id,
          // we are asking for external project metrics, always use the latest version
          version: fromExternalProject?.externalProjectId ? undefined : currentVersion
        }
      : null,
    false,
    {
      keepPreviousData: true,
      fromShare,
      fromExternalProject
    }
  )

  return {
    events: data?.events,
    error,
    loading
  }
}

const limit = 20

export function useAnalyticsPropValues(
  projectId: string | undefined,
  eventName: string,
  props: string[] = [],
  searchQuery?: string,
  fromShare?: { shareId?: string; project?: Project },
  fromExternalProject?: { projectId?: string; externalProjectId?: string }
) {
  const { currentVersion, project } = useProjectVersions()

  const { data, error } = useApi(
    AnalyticService.GetEventPropertyValues,
    projectId && props.length > 0
      ? {
          projectId: fromExternalProject?.externalProjectId ?? projectId,
          eventName,
          eventPropertyName: props,
          searchQuery,
          limit,
          version: fromExternalProject?.externalProjectId ? undefined : currentVersion
        }
      : null,
    false,
    { fromShare, fromExternalProject }
  )

  return {
    values: data?.values || [],
    error,
    loading: !data && !error
  }
}

export function resolveVariables(queries: SegmentationQuery[], variablesValues: { [p: string]: string }) {
  const resolve = (expr: SegmentationQuerySelectorExpr) => {
    if (expr.selector) {
      const { value } = expr.selector
      if (value && value[0]?.stringValue?.startsWith('$')) {
        const variable = value[0].stringValue.slice(1)
        const variableValue = variablesValues[variable]
        if (variableValue) {
          expr.selector.value = [{ stringValue: variableValue }]
        } else {
          expr.selector.value = []
        }
      }
    } else if (expr.logicExpr) {
      expr.logicExpr.expressions?.forEach(resolve)
      expr.logicExpr.expressions = (expr.logicExpr?.expressions || []).filter((expr) => {
        if (expr.selector) {
          return expr.selector.value && expr.selector.value?.length > 0
        } else {
          return expr.logicExpr?.expressions && expr.logicExpr?.expressions?.length > 0
        }
      })
    }
  }

  return produce(queries, (draft) => {
    draft.forEach((query) => {
      if (query.selectorExpr) {
        resolve(query.selectorExpr)
      }
      if (query.resource?.cohortsQuery?.groups) {
        query.resource.cohortsQuery.groups.forEach((group) => {
          if (group.filters) {
            group.filters.forEach((filter) => {
              if (filter.selectorExpr) {
                resolve(filter.selectorExpr)
              }
            })
          }
        })
      }
    })
  })
}

export function expandArraySelectors(queries: SegmentationQuery[]): SegmentationQuery[] {
  return queries.map((q) => ({
    ...q,
    selectorExpr: q.selectorExpr ? expandArraySelector(q.selectorExpr) : undefined
  }))
}

function expandArraySelector(expr: SegmentationQuerySelectorExpr): SegmentationQuerySelectorExpr {
  if (expr.logicExpr) {
    return {
      logicExpr: {
        operator: expr.logicExpr.operator,
        expressions: expr.logicExpr.expressions?.map((e) => expandArraySelector(e))
      }
    }
  } else if (expr.selector) {
    const values = expr.selector.value || []
    if (values.length > 1) {
      return {
        logicExpr: {
          operator: JoinOperator.OR,
          expressions: values.map((v) => ({
            selector: {
              key: expr.selector.key,
              value: [v],
              operator: expr.selector.operator
            }
          }))
        }
      }
    }
  }
  return expr
}
