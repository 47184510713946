import classNames from 'lib/classnames'
import { useEffect, useRef, useState } from 'react'
import { HiOutlineClock } from 'react-icons/hi'

const inputCls = 'border-0 w-[1.5em] py-0 px-0.5 text-xs focus:ring-0'

interface Props {
  value: string
  disabled: boolean
  onChange: (value: string) => void
}

export function TimeInput({ value, disabled, onChange }: Props) {
  const [hour, setHour] = useState(value.split(':')[0] || '00')
  const [minute, setMinute] = useState(value.split(':')[1] || '00')
  const refHour = useRef<HTMLInputElement>(null)
  const refMinute = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const [h, m] = value.split(':')
    if (+h !== +hour || !hour) {
      setHour(h)
    }
    if (+m !== +minute || !minute) {
      setMinute(m)
    }
  }, [value])

  useEffect(() => {
    onChange(`${hour}:${minute}`)
  }, [hour, minute])

  const onChangeHour = (e) => {
    const { value } = e.target
    if (value.length < 3 && +value < 24) {
      setHour(value)
    }
    if (+value > 2) {
      setTimeout(() => {
        refMinute.current?.focus()
      })
    }
  }

  const onBlurHour = () => {
    if (hour.length < 2) {
      setHour('0' + hour)
    }
  }

  const onKeyDownHour = (e) => {
    switch (e.key) {
      case 'ArrowRight':
        if (e.target.selectionEnd === e.target.value.length) {
          refMinute.current?.focus()
        }
        return
      case 'ArrowUp':
        if (+hour < 23) {
          setHour((+hour + 1).toString().padStart(2, '0'))
        }
        e.preventDefault()
        return
      case 'ArrowDown':
        if (+hour > 0) {
          setHour((+hour - 1).toString().padStart(2, '0'))
        }
        e.preventDefault()
        return
    }
  }

  const onChangeMinute = (e) => {
    const { value } = e.target
    if (value.length < 3 && +value < 60) {
      setMinute(value)
    }
  }

  const onBlurMinute = (e) => {
    if (minute?.length < 2) {
      setMinute('0' + minute)
    }
  }

  const onKeyDownMinute = (e) => {
    switch (e.key) {
      case 'ArrowLeft':
        if (e.target.selectionStart === 0) {
          refHour.current?.focus()
        }
        return
      case 'ArrowUp':
        if (+minute < 59) {
          setMinute((+minute + 1).toString().padStart(2, '0'))
        }
        e.preventDefault()
        return
      case 'ArrowDown':
        if (+minute > 0) {
          setMinute((+minute - 1).toString().padStart(2, '0'))
        }
        e.preventDefault()
        return
    }
  }

  const selectInput = (e) => {
    setTimeout(() => {
      e.target.select()
    })
  }

  return (
    <div
      className={classNames(
        'dark:bg-sentio-gray-100 inline-flex items-center gap-0.5 rounded-md border bg-white px-2 py-[0.375rem] leading-4',
        disabled && 'opacity-30'
      )}
    >
      <input
        className={inputCls}
        type="text"
        value={disabled ? '--' : hour || '00'}
        ref={refHour}
        onChange={onChangeHour}
        onFocus={selectInput}
        onBlur={onBlurHour}
        onKeyDown={onKeyDownHour}
      />
      :
      <input
        className={inputCls}
        type="text"
        value={disabled ? '--' : minute || '00'}
        ref={refMinute}
        onChange={onChangeMinute}
        onFocus={selectInput}
        onBlur={onBlurMinute}
        onKeyDown={onKeyDownMinute}
      />
      <HiOutlineClock className="ml-2 text-gray-600" />
    </div>
  )
}
