import { useMemo, useState } from 'react'
import { ComboSelect } from '../common/select/NewComboSelect'
import { ComboInput } from '../common/input/ComboInput'
import Button from 'components/common/buttons/NewButton'
import { DEFAULT_TZ } from 'lib/time'

interface Props {
  value: string
  onChange: (value?: string) => void
}

export function TimeZonePicker({ value, onChange }: Props) {
  const [input, setInput] = useState('')

  const { timeZones, timeZoneOffset } = useMemo(() => {
    const timeZones = [DEFAULT_TZ, ...Intl.supportedValuesOf('timeZone').filter((x) => x != DEFAULT_TZ)]
    const now = new Date()
    const timeZoneOffset = {}
    timeZones.forEach((timeZone) => {
      const dateString = Intl.DateTimeFormat([], { timeZone, timeZoneName: 'longOffset' }).format(now)
      const offset = dateString.split(' ')[1].replace('GMT', 'UTC')
      timeZoneOffset[timeZone] = offset
    })
    return {
      timeZones,
      timeZoneOffset
    }
  }, [])

  return (
    <div className="grid grid-cols-1 items-center gap-y-2 sm:flex sm:gap-y-0">
      <div className="flex shrink-0 items-center">
        <span className="text-ilabel mr-2.5 font-medium">Time Zone</span>
      </div>
      <ComboInput
        className="text-icontent focus:ring-primary-500 focus:border-primary-500 dark:bg-sentio-gray-100 border-border-color block h-8 w-full rounded-md bg-white sm:w-[216px]"
        inputClassName="rounded"
        value={''}
        options={timeZones}
        displayFn={(z) => (
          <div className="group flex items-center justify-between gap-3">
            <div className="inline-flex items-center">
              {z}
              {z == DEFAULT_TZ && (
                <span className="group-hover:bg-primary text-primary-600 ml-1 inline-block rounded bg-blue-100 px-1 py-0.5 text-[11px] dark:bg-blue-800">
                  Browser time
                </span>
              )}
            </div>
            <span className="dark:text-text-foreground rounded border bg-gray-50 px-1 text-[11px] text-gray-800">
              {timeZoneOffset[z]}
            </span>
          </div>
        )}
        onChange={onChange}
        placeholder={
          <div className="flex items-center justify-between">
            <span className="text-text-foreground truncate text-xs" title={value}>
              {value}
            </span>
            <span className="rounded border bg-gray-50 px-1 py-0.5 text-[11px] leading-[14px] text-gray-800">
              {timeZoneOffset[value]}
            </span>
          </div>
        }
      />
    </div>
  )
}
