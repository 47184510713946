import { Any } from '../gen/service/common'
import dayjs from 'dayjs'

export function any2string(v: Any | undefined | null, nf?: Intl.NumberFormat): string {
  if (!v) {
    return ''
  }
  if (v.stringValue != null) {
    return v.stringValue
  } else if (v.boolValue != null) {
    return String(v.boolValue)
  } else if (v.doubleValue != null) {
    if (nf) {
      return nf.format(v.doubleValue)
    }
    return String(v.doubleValue)
  } else if (v.intValue != null) {
    if (nf) {
      return nf.format(v.intValue)
    }
    return String(v.intValue)
  } else if (v.longValue != null) {
    return String(v.longValue)
  } else if (v.dateValue != null) {
    return dayjs(v.dateValue).toISOString()
  } else if (v.listValue != null) {
    return '[' + (v.listValue?.values || []).join(',') + ']'
  }
  return ''
}

export function parseAny(s: string): Any {
  const number = Number(s)
  if (isNaN(number)) {
    return { stringValue: s }
  }
  if (Number.isInteger(number)) {
    return { intValue: number }
  }

  return { doubleValue: number }
}

export function any2number(a: Any | undefined): number | undefined {
  if (a?.doubleValue != null) {
    return a.doubleValue
  } else if (a?.intValue != null) {
    return a.intValue
  }
  return undefined
}
