import {
  dateTimeToString,
  DateTimeValue,
  DEFAULT_TZ,
  FULL_DATE_TIME_PATTERN,
  isBefore,
  parseRelativeTime,
  RelativeTime,
  relativeTimeToString
} from 'lib/time'
import { useEffect, useMemo, useState } from 'react'
import Button from 'components/common/buttons/NewButton'
import classNames from 'lib/classnames'
import { PopoverButton } from 'components/common/popper/PopoverButton'
import Calendar from './Calendar'
import { PresetPicker } from './PresetPicker'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import { CalendarIcon } from '@heroicons/react/24/outline'
import { TimeZonePicker } from './TimeZonePicker'
import { TimeInput } from './TimeInput'
import { DATE_FORMAT, DateInput } from './DateInput'
import pluralize from 'pluralize'
import { AutoRefreshButton } from './AutoRefreshButton'

dayjs.extend(duration)
dayjs.extend(relativeTime)

interface Props {
  startTime?: DateTimeValue
  endTime?: DateTimeValue
  tz?: string
  onChange: (start?: DateTimeValue, end?: DateTimeValue, tz?: string) => void
  onRefresh?: () => void
  skipApply?: boolean
}

const formatTimeRange = (start?: DateTimeValue, end?: DateTimeValue) => {
  if (!start || !end) {
    return ''
  }
  const s = formatTime(start)
  const e = formatTime(end)
  if (s == e) {
    return s
  }
  return `${s} - ${e}`
}

const formatTime = (value?: DateTimeValue) => {
  if (!value) {
    return ''
  }
  if (dayjs.isDayjs(value)) {
    return value.format(FULL_DATE_TIME_PATTERN)
  } else {
    const v = value as RelativeTime
    if (v.align) {
      if (v.value == 0 || v.unit == null) {
        return `this ${pluralize.singular(v.align)}`
      }
      if (v.sign == -1) {
        return `previous ${pluralize(v.align, v.value, v.value != 1)}`
      }
      return relativeTimeToString(value)
    }
    if (v.value == 0 || v.unit == null) {
      return 'now'
    } else {
      return v.value + ' ' + (v.value > 1 ? v.unit : v.unit.slice(0, -1)) + (value?.sign == -1 ? ' ago' : ' from now')
    }
  }
}

const formatTimeInput = (value?: DateTimeValue) => {
  return dayjs.isDayjs(value) ? value.format('HH:mm') : ''
}

export default function TimeRangePicker({ startTime, endTime, tz, onChange, onRefresh, skipApply = false }: Props) {
  const [start, setStart] = useState(startTime)
  const [end, setEnd] = useState(endTime)
  const [timeZone, setTimeZone] = useState(tz)

  const onTimeChange = (start?: DateTimeValue, end?: DateTimeValue, tz?: string) => {
    setStart(start)
    setEnd(end)
    setTimeZone(tz)
  }

  useEffect(() => {
    onTimeChange(startTime, endTime, tz)
  }, [startTime, endTime, tz])

  const onSelectStartDate = (date) => {
    if (dayjs.isDayjs(start)) {
      date = date.hour(start.hour()).minute(start.minute())
    }
    setStart(date)
  }

  const onSelectEndDate = (date) => {
    if (dayjs.isDayjs(end)) {
      date = date.hour(end.hour()).minute(end.minute())
    }
    setEnd(date)
  }

  const onChangeStartDate = (value) => {
    let date: DateTimeValue | null = dayjs(value, DATE_FORMAT, true)
    if (!date.isValid()) {
      date = parseRelativeTime(value)
    }
    if (dayjs.isDayjs(date) && dayjs.isDayjs(start)) {
      date = date.hour(start.hour()).minute(start.minute())
    }
    if (date) {
      setStart(date)
    } else {
      setStart(undefined)
    }
  }

  const onChangeEndDate = (value) => {
    let date: DateTimeValue | null = dayjs(value, DATE_FORMAT, true)
    if (!date.isValid()) {
      date = parseRelativeTime(value)
    }
    if (dayjs.isDayjs(date) && dayjs.isDayjs(end)) {
      date = date.hour(end.hour()).minute(end.minute())
    }
    if (date) {
      setEnd(date)
    } else {
      setEnd(undefined)
    }
  }

  const onChangeStartTime = (value) => {
    if (!dayjs.isDayjs(start)) {
      return
    }
    const [hour, minute] = value.split(':')
    const date = start.hour(hour).minute(minute)
    setStart(date)
  }

  const onChangeEndTime = (value) => {
    if (!dayjs.isDayjs(end)) {
      return
    }
    const [hour, minute] = value.split(':')
    const date = end?.hour(hour).minute(minute)
    setEnd(date)
  }

  const isClean =
    dateTimeToString(start) == dateTimeToString(startTime) &&
    dateTimeToString(end) == dateTimeToString(endTime) &&
    tz == timeZone
  const isValid = isBefore(start, end)

  const timeZoneStatus = useMemo(() => {
    if (timeZone && timeZone !== DEFAULT_TZ) {
      const now = new Date()
      const dateString = Intl.DateTimeFormat([], { timeZone, timeZoneName: 'longOffset' }).format(now)
      const offset = dateString.split(' ')[1].replace('GMT', 'UTC')
      return (
        <span className="rounded border bg-gray-50 px-1 py-0.5 text-[11px] leading-[14px] text-gray-800">{offset}</span>
      )
    }
    return undefined
  }, [timeZone])

  return (
    <div className="flex w-full sm:w-fit sm:pr-0">
      <PopoverButton
        containerClassName="w-full sm:w-fit"
        content={({ close }) => (
          <>
            <div className="grid grid-cols-1 justify-items-stretch sm:flex">
              <PresetPicker
                onSelect={(x, y) => {
                  onChange(x, y, timeZone)
                  close()
                }}
              />
              <div className="pb-px sm:p-4">
                <div className="hidden gap-3 sm:flex">
                  <Calendar
                    value={dayjs.isDayjs(start) ? start : dayjs().subtract(1, 'month')}
                    start={dayjs.isDayjs(start) ? start : undefined}
                    end={dayjs.isDayjs(end) ? end : undefined}
                    onSelect={onSelectStartDate}
                  />
                  <div className="border-r" />
                  <Calendar
                    value={dayjs.isDayjs(end) ? end : dayjs()}
                    start={dayjs.isDayjs(start) ? start : undefined}
                    end={dayjs.isDayjs(end) ? end : undefined}
                    onSelect={onSelectEndDate}
                  />
                </div>
                <div className="bg-[#fafafa] p-4 dark:bg-[#2e2e2e] sm:mt-4 sm:p-3">
                  <div className="text-ilabel grid grid-cols-1 justify-between gap-y-2 sm:flex sm:gap-y-0">
                    <label className="items-center space-y-2 sm:flex sm:space-y-0">
                      <span className="mr-2.5 font-medium">From</span>
                      <DateInput value={start} onChange={onChangeStartDate} />
                      <span className="hidden sm:inline">
                        <TimeInput
                          value={formatTimeInput(start)}
                          disabled={!dayjs.isDayjs(start)}
                          onChange={onChangeStartTime}
                        />
                      </span>
                    </label>
                    <label className="items-center space-y-2 sm:flex sm:space-y-0">
                      <span className="mr-2.5 font-medium">To</span>
                      <DateInput value={end} onChange={onChangeEndDate} />
                      <span className="hidden sm:inline">
                        <TimeInput
                          value={formatTimeInput(end)}
                          disabled={!dayjs.isDayjs(end)}
                          onChange={onChangeEndTime}
                        />
                      </span>
                    </label>
                  </div>
                  <div className="mt-2.5 grid grid-cols-1 justify-items-stretch gap-y-4 sm:flex sm:justify-between">
                    <TimeZonePicker value={timeZone || DEFAULT_TZ} onChange={setTimeZone} />
                    <div className="grid grid-cols-1 justify-items-stretch gap-2 sm:flex">
                      <Button
                        role="secondary"
                        size="md"
                        disabled={isClean}
                        onClick={() => onTimeChange(startTime, endTime, tz)}
                        className="w-[calc(100vw-4rem)] sm:w-fit"
                      >
                        Reset
                      </Button>
                      <Button
                        role="primary"
                        size="md"
                        onClick={() => {
                          onChange(start, end, timeZone)
                          close()
                        }}
                        disabled={isClean || !isValid}
                        className="w-[calc(100vw-4rem)] sm:w-fit"
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        contentClassName="z-30 mt-3 rounded-md bg-gray-50 shadow ring-1 ring-gray-200"
        as="div"
      >
        <div
          className={classNames(
            'text-ilabel hover:bg-primary-50 dark:hover:bg-navbar-hover-background dark:hover:text-text-foreground flex h-[30px] cursor-pointer items-center gap-1.5 border border-gray-300 pl-2 pr-4 font-medium',
            onRefresh ? 'rounded-l-md border-r-0' : 'rounded-md',
            'flex-1',
            isValid
              ? isClean
                ? ''
                : 'dark:bg-primary-600 bg-blue-50 dark:text-white'
              : 'bg-red-100 dark:bg-red-800/80 dark:text-white'
          )}
          suppressHydrationWarning
        >
          <CalendarIcon className="h-5 w-5 text-inherit" />
          {formatTimeRange(start, end)}
          {timeZoneStatus}
        </div>
      </PopoverButton>

      {onRefresh && (
        <AutoRefreshButton
          onClick={() => {
            onTimeChange(startTime, endTime, tz)
            onRefresh()
          }}
        />
      )}
    </div>
  )
}
